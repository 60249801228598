
















































































































































































@import "~@/assets/styles/helpers/variables";
.table::v-deep {
    background: #FFF;
    .v-data-table__expanded__content {
      box-shadow: none;
      padding: 60px;
      background: #FFF;
    }

    tbody {
      tr {
        td:nth-child(1), th:nth-child(1) {
          width: 5%;
        }
        td:nth-child(2), th:nth-child(2) {
          width: 31.66%;
          text-align: left !important;

        }
        td:nth-child(3), th:nth-child(3) {
          width: 31.66%;
          text-align: center !important;
        }
        td:nth-child(4), th:nth-child(4) {
          width: 31.66%;
          text-align: right !important;
        }
      }
    }

    .table-premios-td {
      padding: 10px;
      .v-data-table__wrapper {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
      }
    }

    .color-red {
      color: $colorDanger;
    }

    .color-green {
      color: $colorSuccess;
    }

    .total-final {
      color: #62626c;
    }

    .description-final {
      color: #5076ff;
    }
  }

h1 {
  color: #4a5275;
  text-transform: none;
  font-size: 20px;
}

